import { fullUrl, ampUrl } from 'lib/formatter/url'
import Head from 'next/head'

import { FB_APP_ID, FB_PAGE_ID } from '../../config/app.config'

const titleSuffix = ' - YP | South China Morning Post'

const renderTitle = (title = '', withSuffix = true) => {
  return `${title}${withSuffix ? titleSuffix : ''}`
}

const renderSchema = ({ title, ogTitle, ogDescription, contentLock }) => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  publisher: {
    '@type': 'NewsMediaOrganization',
    name: 'South China Morning Post',
    logo: {
      '@type': 'ImageObject',
      url: 'https://cdn2.i-scmp.com/sites/all/modules/custom/scmp_masthead/images/publisher_logo.png',
      width: 600,
      height: 60,
    },
  },
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://example.org/article',
  },
  headline: ogTitle || renderTitle(title, true),
  description: ogDescription,
  publishingPrinciples: 'https://www.scmp.com/policies-and-standards',
  isAccessibleForFree: !contentLock,
  hasPart: {
    '@type': 'WebPageElement',
    isAccessibleForFree: !contentLock,
    cssSelector: '.paywalled-content',
  },
})

const Meta = (props) => {
  const {
    title = 'News, entertainment and advice for global teens',
    description = 'The latest news, advice, trends, opinion and multimedia on teen interests and issues from Young' +
      ' Post at South China Morning Post',
    siteName = 'Young Post',
    ogTitle,
    ogDescription,
    ogImage = 'yp/assets/png/ogImage.png',
    withSuffix = true,
    url = '/yp',
    canonical = url,
    isAmpHtml,
    isArticle,
  } = props

  return (
    <Head>
      <title>{renderTitle(title, withSuffix)}</title>
      <link rel="canonical" href={fullUrl(canonical)} key="canonical" />
      {isAmpHtml && <link rel="amphtml" href={ampUrl(canonical)} key="amphtml" />}
      <meta name="description" content={description} key="description" />
      <meta
        name="amp-google-client-id-api"
        content="googleanalytics"
        key="amp-google-client-id-api"
      />
      <meta property="og:site_name" content={siteName} key="og:site_name" />
      <meta property="og:url" content={fullUrl(url)} key="og:url" />
      <meta
        property="og:title"
        content={ogTitle || renderTitle(title, withSuffix)}
        key="og:title"
      />
      <meta property="og:description" content={ogDescription || description} key="og:description" />
      <meta property="og:image" content={fullUrl(ogImage)} key="og:image" />
      <meta property="og:image:width" content="700" key="og:image:width" />
      <meta property="og:image:height" content="400" key="og:image:height" />
      <meta property="fb:pages" content={FB_PAGE_ID} key="fb:pages" />
      <meta property="fb:app_id" content={FB_APP_ID} key="fb:app_id" />
      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
      <meta name="twitter:site" content="@twitter" key="twitter:site" />
      <meta
        name="twitter:title"
        content={ogTitle || renderTitle(title, withSuffix)}
        key="twitter:title"
      />
      <meta
        name="twitter:description"
        content={ogDescription || description}
        key="twitter:description"
      />
      <meta name="twitter:image:src" content={fullUrl(ogImage)} key="twitter:image:src" />
      <meta name="mobile-web-app-capable" content="yes" key="mobile-web-app-capable" />
      <meta httpEquiv="X-UA-Compatible" content="IE=Edge ;IE=11" key="X-UA-Compatible" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui, shrink-to-fit=no"
        key="viewport"
      />
      <meta
        name="apple-mobile-web-app-title"
        content="Young Post"
        key="apple-mobile-web-app-title"
      />
      <link href="/yp/assets/png/favicon.png" rel="shortcut icon" type="image/x-icon" />
      <link href="/yp/assets/png/appicon.png" rel="apple-touch-icon" />
      <script async src="https://www.instagram.com/embed.js" />
      {/*<meta name='theme-color' content='#002E3E' key='theme-color' />*/}
      {isArticle && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(renderSchema(props)) }}
          key="ldjson"
        />
      )}
    </Head>
  )
}

export default Meta
